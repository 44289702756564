import {
  type ClientLoaderFunctionArgs,
  Link,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from '@remix-run/react';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import {
  type DtoBlock,
  type DtoGame,
  type DtoProgression,
  EnumsBlockType,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { fromAPIBlockType } from '@lp-lib/game';
import { getBlockOutputsById } from '@lp-lib/game/src/block-outputs';

import { type Action, ActionSheet } from '../components/ActionSheet';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../components/ConfirmCancelModalContext';
import {
  blockOutputsToGradeResult,
  blockTypePlayable,
} from '../components/GameV2/blocks/block-grade-result';
import { ArrowLeftIcon, ArrowRightIcon } from '../components/icons/Arrows';
import { SparkBlockIcon } from '../components/icons/Block';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { EditIcon } from '../components/icons/EditIcon';
import { PlayIcon } from '../components/icons/PlayIcon';
import { XIcon } from '../components/icons/XIcon';
import { TrainingEditorUtils } from '../components/Training/Editor/utils';
import { apiService } from '../services/api-service';
import { fromDTOBlock } from '../utils/api-dto';
import { downloadObjectAsJSON } from '../utils/common';
import { DateUtils } from '../utils/date';
import { tokenWithRedirect } from '../utils/router';
import { CourseLayout } from './admin.courses.client';

function buildAssessmentOutputs(progression: DtoProgression) {
  const blockResults = progression.assessmentResult?.blockResults ?? [];
  const result: {
    blockId: DtoBlock['id'];
    blockType: DtoBlock['type'];
    blockTitle: string;
    outputs: Record<string, ModelsBlockOutput>;
  }[] = [];

  for (const br of blockResults) {
    result.push({
      blockId: br.blockId,
      blockType: br.blockType,
      blockTitle: br.blockType,
      outputs: br.outputs,
    });
  }
  return result;
}

function buildProgressionOutputs(
  progression: DtoProgression,
  blocks: DtoBlock[],
  playableOnly = true
) {
  const blockOutputsMap = progression.blockOutputs ?? {};
  const result: {
    blockId: DtoBlock['id'];
    blockType: DtoBlock['type'];
    blockTitle: string;
    outputs: Record<string, ModelsBlockOutput>;
  }[] = [];

  // Get assessment block IDs to exclude them
  const assessmentBlockIds = (
    progression.assessmentResult?.blockResults ?? []
  ).map((br) => br.blockId);

  for (const block of blocks) {
    // Skip if block is not playable when playableOnly is true
    if (playableOnly && !blockTypePlayable(block.type)) continue;

    // Skip if block is part of assessment results
    if (assessmentBlockIds.includes(block.id)) continue;

    const blockOutputs = getBlockOutputsById(block.id, blockOutputsMap);
    result.push({
      blockId: block.id,
      blockType: block.type,
      blockTitle: TrainingEditorUtils.BlockTitle(fromDTOBlock(block)),
      outputs: blockOutputs,
    });
  }
  return result;
}

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const id = action.params.id;
  if (!id) throw new Response('Not Found', { status: 404 });

  const [packResp, progressionResp] = await Promise.all([
    tokenWithRedirect(
      () =>
        apiService.gamePack.getGamePackById(id, { blocks: true, games: true }),
      action.request.url,
      { admin: true }
    ),
    tokenWithRedirect(
      () => apiService.progression.getPackProgressions(id),
      action.request.url,
      { admin: true }
    ),
  ]);
  return {
    pack: packResp.data.gamePack,
    games: packResp.data.games ?? [],
    blocks: packResp.data.blocks ?? [],
    progressions: progressionResp.data.progressions,
  };
}

function useCalProgressionPerf(
  blocks: DtoBlock[],
  progressionOutputs?:
    | ReturnType<typeof buildAssessmentOutputs>
    | ReturnType<typeof buildProgressionOutputs>,
  progression?: DtoProgression
) {
  return useMemo(() => {
    let passed = 0;
    let total = 0;

    // Get assessment block IDs to exclude them from calculations
    const assessmentBlockIds = new Set(
      (progression?.assessmentResult?.blockResults ?? []).map(
        (br) => br.blockId
      )
    );

    for (const block of blocks) {
      if (!blockTypePlayable(block.type)) continue;
      // Skip scenario blocks that have evaluation turned off
      if (
        block.type === EnumsBlockType.BlockTypeScenario &&
        block.fields.skipRubric
      )
        continue;

      // Skip if block is part of assessment results
      if (assessmentBlockIds.has(block.id)) continue;

      const blockOutputs = (progressionOutputs ?? []).find(
        (o) => o.blockId === block.id
      );
      total += 1;
      const numOfOutputs = Object.keys(blockOutputs?.outputs ?? {}).length;
      const results =
        blockOutputs?.outputs && numOfOutputs > 0
          ? blockOutputsToGradeResult(block, blockOutputs?.outputs)
          : null;
      if (results?.status === 'passed') {
        passed++;
      }
    }
    const score = total > 0 ? passed / total : 0;
    return { passed, total, score };
  }, [blocks, progressionOutputs, progression]);
}

function ProgressionDetailsPanel(props: {
  blocks: DtoBlock[];
  progression: DtoProgression;
  progressionOutputs?:
    | ReturnType<typeof buildAssessmentOutputs>
    | ReturnType<typeof buildProgressionOutputs>;
  onClose: () => void;
  onDownload: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
  isAssessment?: boolean;
}) {
  const {
    blocks,
    progression,
    progressionOutputs,
    isAssessment = false,
  } = props;
  const username =
    progression.learner?.username ?? progression.learner?.email ?? 'Guest';
  const score = progression.assessmentResult?.score ?? 0;

  return (
    <div className='h-full'>
      <header>
        <div className='px-5 h-20 flex items-center justify-between border-b border-secondary'>
          <div className='text-white flex flex-col'>
            <div className='font-bold'>{`${username}'s ${
              isAssessment ? 'Assessment' : 'Progression'
            } Details`}</div>
            <div className='text-sms'>{progression.id}</div>
            <div className='text-sms flex items-center gap-1'>
              <span className='font-bold'>Assessment Score:</span>
              <span>{Math.round(score * 100)}%</span>
            </div>
          </div>
          <div className='flex flex-col gap-2 items-end relative'>
            <button
              type='button'
              onClick={props.onClose}
              className='btn w-8 h-8 absolute -right-5 -top-5 flex items-center justify-center'
            >
              <XIcon />
            </button>
            <div className='flex items-center gap-5 mr-10'>
              <button
                type='button'
                onClick={props.onDownload}
                className='btn-primary w-30 h-8'
              >
                Download
              </button>
              <div className='flex items-center'>
                <button
                  type='button'
                  onClick={props.onPrevious}
                  className='btn-secondary w-8 h-8 rounded-r-none flex items-center justify-center'
                  disabled={!props.onPrevious}
                >
                  <ArrowLeftIcon className='w-4 h-4 fill-current' />
                </button>
                <button
                  type='button'
                  onClick={props.onNext}
                  className='btn-secondary w-8 h-8  rounded-l-none flex items-center justify-center'
                  disabled={!props.onNext}
                >
                  <ArrowRightIcon className='w-4 h-4 fill-current' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='h-[calc(100%-5rem)] flex flex-col gap-8 p-5 overflow-auto scrollbar'>
        {(progressionOutputs ?? []).map((o) => {
          const block = blocks.find((b) => b.id === o.blockId);
          const numOfOutputs = Object.keys(o.outputs).length;
          const result =
            block && numOfOutputs > 0
              ? blockOutputsToGradeResult(block, o.outputs)
              : null;
          return (
            <div className='flex flex-col gap-2' key={o.blockId}>
              <div className='flex items-center gap-2.5 truncate'>
                <SparkBlockIcon
                  className='flex-shrink-0 w-6 h-6'
                  blockType={fromAPIBlockType(o.blockType)}
                />
                <div className='text-white truncate'>{o.blockTitle}</div>
                {result && (
                  <div
                    className={`text-sms ${
                      result.status === 'passed'
                        ? 'text-green-001'
                        : 'text-red-002'
                    }`}
                  >
                    {result.status === 'passed' ? 'Correct' : 'Incorrect'}
                  </div>
                )}
              </div>
              {numOfOutputs > 0 ? (
                <table className='w-full text-sms'>
                  <tbody>
                    {Object.entries(o.outputs).map(([key, e]) => (
                      <tr key={key}>
                        <td className='w-1/3'>{key}</td>
                        <td className='w-2/3'>{e.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-sms text-secondary'>No data available</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function DeleteProgressionConfirmationModal(): JSX.Element {
  return (
    <div className='text-white flex-col items-center justify-center py-2 px-4'>
      <ConfirmCancelModalText className='text-2xl font-medium'>
        Do you really want to delete this progression?
      </ConfirmCancelModalText>
      <div className='my-2 text-sms text-center'>This is permanent action!</div>
    </div>
  );
}

function ProgressionInfo(props: {
  progression: DtoProgression;
  games: DtoGame[];
  blocks: DtoBlock[];
  nonAssessmentOutputs?: ReturnType<typeof buildProgressionOutputs>;
  onView?: () => void;
  onViewAssessment?: () => void;
  onDownload?: () => void;
  onDelete?: (id: string) => void;
}) {
  const { progression, games, blocks, nonAssessmentOutputs } = props;
  const { data: org } = useSWR(
    progression.learner?.orgId
      ? ['organizations', progression.learner.orgId]
      : null,
    async () => {
      if (!progression.learner?.orgId) return null;
      const resp = await apiService.organization.getOrganization(
        progression.learner.orgId
      );
      return resp.data.organization;
    }
  );

  const [progress, assessmentScoreResult] = useMemo(() => {
    const minigameProgressMap = progression.progress ?? {};
    const minigameCompletedCount = Object.values(minigameProgressMap).reduce(
      (acc, curr) => {
        return acc + (curr.completedAt ? 1 : 0);
      },
      0
    );
    const blockProgressMap = progression.blockProgress ?? {};
    const blockCompletedCount = Object.values(blockProgressMap).reduce(
      (acc, curr) => {
        return acc + (curr.completedAt ? 1 : 0);
      },
      0
    );
    const assessmentScoreResult = progression.assessmentResult?.score;
    return [
      `${minigameCompletedCount}/${games.length} minigames, ${blockCompletedCount}/${blocks.length} blocks`,
      assessmentScoreResult,
      false,
    ];
  }, [
    progression.progress,
    progression.blockProgress,
    games.length,
    blocks.length,
    progression.assessmentResult,
  ]);

  const { passed, total, score } = useCalProgressionPerf(
    blocks,
    nonAssessmentOutputs,
    progression
  );

  return (
    <tr className='w-full h-10 text-sms hover:bg-lp-gray-002 odd:bg-lp-gray-001'>
      <td>
        <Link className='underline' to={`/admin/users?uid=${progression.uid}`}>
          {progression.learner?.username ??
            progression.learner?.email ??
            'Guest'}
        </Link>
      </td>
      <td>{org?.name ?? 'N/A'}</td>
      <td>{DateUtils.FormatDatetime(progression.createdAt)}</td>
      <td>{progress}</td>
      <td>
        {Math.round(score * 100)}% ({passed}/{total})
      </td>
      <td>
        <button
          type='button'
          className='btn text-primary underline mx-1'
          onClick={props.onView}
        >
          View
        </button>
        <button
          type='button'
          className='btn text-tertiary underline mx-1'
          onClick={props.onDownload}
        >
          Download
        </button>
      </td>
      <td>
        {/* !== undefined because 0 is a valid score but a falsey value */}
        {assessmentScoreResult !== undefined ? (
          <button
            type='button'
            className='btn text-primary underline'
            onClick={props.onViewAssessment}
          >
            {Math.round(assessmentScoreResult * 100)}%
          </button>
        ) : (
          'N/A'
        )}
      </td>
      <td>
        <button
          type='button'
          onClick={() => props.onDelete?.(props.progression.id)}
        >
          <DeleteIcon className='w-5 h-5 fill-current text-red-500 hover:text-red-600' />
        </button>
      </td>
    </tr>
  );
}

export function Component() {
  const { progressions, pack, blocks, games } =
    useLoaderData<typeof clientLoader>();

  const revalidator = useRevalidator();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const [progressionId, setProgressionId] = useState<string | null>(null);
  const [assessmentProgressionId, setAssessmentProgressionId] = useState<
    string | null
  >(null);

  const assessmentOutputsMap = useMemo(() => {
    const outputsMap: Record<
      DtoProgression['id'],
      ReturnType<typeof buildAssessmentOutputs>
    > = {};
    for (const progression of progressions) {
      const outputs = buildAssessmentOutputs(progression);
      outputsMap[progression.id] = outputs;
    }
    return outputsMap;
  }, [progressions]);

  const progressionOutputsMap = useMemo(() => {
    const outputsMap: Record<
      DtoProgression['id'],
      ReturnType<typeof buildProgressionOutputs>
    > = {};
    for (const progression of progressions) {
      const outputs = buildProgressionOutputs(progression, blocks);
      outputsMap[progression.id] = outputs;
    }
    return outputsMap;
  }, [progressions, blocks]);

  const createDownloadData = (
    progression: DtoProgression,
    progressionOutputs?: ReturnType<typeof buildAssessmentOutputs>
  ) => {
    return {
      id: progression.id,
      uid: progression.uid,
      username: progression.learner?.username ?? null,
      email: progression.learner?.email ?? null,
      orgId: progression.learner?.orgId ?? null,
      packId: pack.id,
      data: progressionOutputs ?? [],
      assessmentScore: progression.assessmentResult?.score ?? 'N/A',
      startDate: progression.createdAt,
    };
  };
  const download = (progressionId: string) => {
    const progression = progressions.find((p) => p.id === progressionId);
    if (!progression) return;
    downloadObjectAsJSON(
      createDownloadData(progression, assessmentOutputsMap[progressionId]),
      `progression-${progression.id}.json`
    );
  };

  const downloadAll = () => {
    const data = progressions.map((p) =>
      createDownloadData(p, assessmentOutputsMap[p.id])
    );
    downloadObjectAsJSON(data, `course-${pack.id}-progressions`);
  };

  const averageAssessmentScore = useMemo(() => {
    const assessmentResults = progressions.filter((p) => p.assessmentResult);
    const total = assessmentResults.reduce((acc, p) => {
      const result = p.assessmentResult?.score ?? 0;
      return acc + result;
    }, 0);
    return (total * 100) / assessmentResults.length;
  }, [progressions]);

  const progression = progressions.find((p) => p.id === progressionId);
  const index = progressions.findIndex((p) => p.id === progressionId);
  const prev = progressions[index - 1];
  const next = progressions[index + 1];

  const assessmentProgression = progressions.find(
    (p) => p.id === assessmentProgressionId
  );
  const assessmentIndex = progressions.findIndex(
    (p) => p.id === assessmentProgressionId
  );
  const assessmentPrev = progressions[assessmentIndex - 1];
  const assessmentNext = progressions[assessmentIndex + 1];

  const navigate = useNavigate();
  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit',
      icon: <EditIcon />,
      text: 'Edit Course',
      onClick: () => {
        navigate(`/trainings/${pack.id}/edit`);
      },
    },
    {
      kind: 'button',
      key: 'play',
      icon: <PlayIcon />,
      text: 'Play Course',
      onClick: () => {
        navigate(`/game-packs/${pack.id}/overworld`);
      },
    },
  ];

  const handleDeleteProgression = async (id: string) => {
    const response = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: <DeleteProgressionConfirmationModal />,
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;
    await apiService.progression.deleteProgression({
      assessment: true,
      id,
    });
    revalidator.revalidate();
  };

  return (
    <CourseLayout contentKey={`course-progressions-${pack.id}`}>
      <div className='w-full relative text-white p-10'>
        <header className='flex justify-between items-center'>
          <div className='flex flex-col items-start gap-2'>
            <h1 className='font-bold text-3xl'>Progressions for {pack.name}</h1>
            <h2 className='font-bold text-2xl'>
              Assessment Avg:{' '}
              {averageAssessmentScore > 0
                ? `${averageAssessmentScore.toFixed(2)}%`
                : 'N/A'}
            </h2>
          </div>
          <div className='flex items-center gap-2'>
            <button
              type='button'
              className='btn-primary w-40 h-10'
              onClick={() => downloadAll()}
            >
              Download All
            </button>
            <ActionSheet
              actions={actions}
              btnSizingClassName='w-10 h-10'
              placement='bottom-end'
            />
          </div>
        </header>
        <table className='w-full my-10'>
          <thead className='text-left h-12 text-base text-bold'>
            <tr>
              <th>Learner</th>
              <th>Organization</th>
              <th>Started At</th>
              <th>Progress</th>
              <th>Non-Assessment Score</th>
              <th>Details</th>
              <th>Assessment Score</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {progressions.map((progression) => (
              <ProgressionInfo
                key={progression.id}
                progression={progression}
                games={games}
                blocks={blocks}
                onView={() => setProgressionId(progression.id)}
                onViewAssessment={() =>
                  setAssessmentProgressionId(progression.id)
                }
                onDownload={() => download(progression.id)}
                nonAssessmentOutputs={progressionOutputsMap[progression.id]}
                onDelete={handleDeleteProgression}
              />
            ))}
          </tbody>
        </table>

        {progression && (
          <div className='fixed inset-0 z-50'>
            <div className='absolute inset-0 bg-lp-black-001' />
            <div className='absolute top-0 bottom-0 right-0 w-1/2 bg-layer-001'>
              <ProgressionDetailsPanel
                blocks={blocks}
                progression={progression}
                progressionOutputs={progressionOutputsMap[progression.id]}
                onClose={() => setProgressionId(null)}
                onDownload={() => download(progression.id)}
                onPrevious={
                  prev?.id ? () => setProgressionId(prev.id) : undefined
                }
                onNext={next?.id ? () => setProgressionId(next.id) : undefined}
                isAssessment={false}
              />
            </div>
          </div>
        )}

        {/* Assessment Panel */}
        {assessmentProgressionId && (
          <div className='fixed inset-0 z-50'>
            <div className='absolute inset-0 bg-lp-black-001' />
            <div className='absolute top-0 bottom-0 right-0 w-1/2 bg-layer-001'>
              {assessmentProgression && (
                <ProgressionDetailsPanel
                  blocks={blocks}
                  progression={assessmentProgression}
                  progressionOutputs={
                    assessmentOutputsMap[assessmentProgressionId]
                  }
                  onClose={() => setAssessmentProgressionId(null)}
                  onDownload={() => download(assessmentProgressionId)}
                  onPrevious={
                    assessmentPrev?.id
                      ? () => setAssessmentProgressionId(assessmentPrev.id)
                      : undefined
                  }
                  onNext={
                    assessmentNext?.id
                      ? () => setAssessmentProgressionId(assessmentNext.id)
                      : undefined
                  }
                  isAssessment={true}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </CourseLayout>
  );
}
